import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber, formatString } from '../../../../helpers/utils';
import { INotificationsAggregator } from '../../../../hooks/useChat';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import { api } from '../../../../utils/api';
import { Broker, Merchant, Purchase } from '../../../../utils/types';
import { DealConfirm } from '../../../blocks/DealConfirm';
import { ClockIcon, Defaults, HtmlText, RoundBox, Row, Timer } from '../../../common';
import { ToMessageIcon } from '../../../common/Icons';
import { DealAction, DealCancelAction } from '../../../common/panels/controls';
import { PayCard } from '../../../common/payCard/PayCard';
import { TabsComponent } from '../../../common/tabs';
import { AttachmentBox } from '../../../controls/AttachmentBox';
import ChatEx, { ChatMessage } from '../../../controls/Chat';
import styles from './styles.module.scss';

interface IPaymentProps {
  purchase: Purchase;
  broker?: Broker;
  onConfirmPayment: (mask?: string) => Promise<void>;
  onCancelDeal: (end?: () => void) => void;
  merchant?: Merchant;
  notifications: INotificationsAggregator;
}

export const PaymentMain: FC<IPaymentProps> = ({
  purchase,
  onConfirmPayment,
  onCancelDeal,
  merchant,
  broker,
  notifications,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('max-width: 768px');

  const [showConfirmPayment, setShowConfirmPayment] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [showReceiptRequired, setShowReceiptRequired] = useState(false);
  const [receiptPresent, setReceiptPresent] = useState<boolean | string>(false);
  const [busy, setBusy] = useState(false);
  const [cancelDealLoading, setCancelDealLoading] = useState(false);

  const [mask, setMask] = useState<string>(purchase.mask ?? '');

  const confirmPayment = () => {
    merchant.is_receipt_required
      ? setShowReceiptRequired(true)
      : setShowConfirmPayment(true);
  };
  const confirmCancel = () => setShowConfirmCancel(true);

  const maskRequired = useMemo(() => {
    return (
      merchant?.required_mask &&
      broker?.is_card &&
      !purchase.deal?.['mask'] &&
      !purchase.autodeal
    );
  }, [
    merchant?.required_mask,
    purchase.deal?.['mask'],
    broker?.is_card,
    !purchase.autodeal,
  ]);

  const maskComplete = useMemo(() => {
    return typeof mask === 'string' && !!mask.match(/\d{4}-\d{4}-\d{4}-\d{4}/g);
  }, [mask]);

  const onConfirmPaymentInternal = () => {
    let isReady = true;

    if (merchant.is_receipt_required) isReady &&= !!receiptPresent;
    if (maskRequired) {
      if (!maskComplete) {
        setShowReceiptRequired(false);
        setShowConfirmPayment(true);
      }
      isReady &&= maskComplete;
    }

    if (isReady) {
      setBusy(true);
      onConfirmPayment(mask || undefined).finally(() => setBusy(false));
    }
  };

  const initialized = useMemo(() => {
    return !!purchase.deal || !!purchase.autodeal;
  }, [!!purchase.deal, !!purchase.autodeal]);

  const tabs = {
    chat: (
      <ChatEx
        user={purchase.deal?.seller.nickname}
        height={'300px'}
        noHeader
        messages={notifications.personalChat.messages}
        onSendMessage={notifications.sendUserMessage}
      />
    ),
  };

  if (!initialized || (purchase.autodeal ? false : !purchase.deal?.requisite)) {
    return (
      <div className={styles.wait}>
        <ClockIcon size={isMobile ? '40' : '80'} fill={'#00B3FB'} />
        <div className={styles.title}>{`${t('purchase.wait.title')}`}</div>
        <div className={styles.hint}>{`${t('purchase.wait.hint')}`}</div>
        <div className={styles.icons}>
          <div className={styles.tip}>
            <div className={styles.icon}>✅</div>
            <div className={styles.text}>{`${t('purchase.wait.guarantee')}`}</div>
          </div>
          <div className={styles.tip}>
            <div className={styles.icon}>👨🏻‍💻</div>
            <div className={styles.text}>{`${t('purchase.wait.support')}`}</div>
          </div>
          <div className={styles.tip}>
            <div className={styles.icon}>🏦</div>
            <div className={styles.text}>{`${t('purchase.wait.topup')}`}</div>
          </div>
        </div>
      </div>
    );
  }

  const UnreadMessagesBox = ({ count }: { count?: number }) =>
    !!count && (
      <RoundBox size={'2vh'} fontSize={'1.6vh'} left={'0.4vh'}>
        {count}
      </RoundBox>
    );

  const onSendReceipt = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files[0]) {
      if (purchase.is_receipt_sent) {
        setReceiptPresent(e.target.files[0].name);
        return;
      }
      api.messages.attach(e.target.files[0]).then((result) => {
        setReceiptPresent(e.target.files[0].name);
        if (!!result && !!result.id) {
          const formedMessage: ChatMessage = {
            type: 'user',
            date: new Date(),
            user: purchase.deal.seller.nickname,
            mediaId: result.id,
          };
          notifications.sendUserMessage(formedMessage);
          api.purchases
            .update(purchase.payment_id, {
              is_receipt_sent: true,
            })
            .then(() => setReceiptPresent(e.target.files[0].name));
        }
      });
    }
  };

  return (
    <div className={styles.container}>
      <DealConfirm
        open={showConfirmCancel}
        k={'purchase.deal.cancel-confirmation'}
        onSubmit={() => {
          setCancelDealLoading(true);
          onCancelDeal(() => setCancelDealLoading(false));
        }}
        onCancel={() => setShowConfirmCancel(false)}
        busy={cancelDealLoading}
      />
      <DealConfirm
        open={showConfirmPayment}
        k={'purchase.deal.payment-confirmation'}
        args={[
          formatNumber((purchase.deal ?? purchase.autodeal).amount_currency),
          purchase.currency.toUpperCase(),
          broker.name,
        ]}
        busy={busy}
        note={'purchase.deal.payment-note'}
        value={purchase.mask ?? mask}
        valuePresent={!!purchase?.mask}
        requiredMask={maskRequired}
        onChange={setMask}
        requisite={purchase.deal?.requisite ?? purchase.requisites}
        onSubmit={onConfirmPaymentInternal}
        onCancel={() => {
          setMask('');
          setShowConfirmPayment(false);
        }}
      />
      <DealConfirm
        open={showReceiptRequired}
        k={'purchase.deal.receipt-required'}
        args={[purchase.requisites]}
        note={'purchase.deal.receipt-required-note'}
        noteArgs={[purchase?.deal?.id]}
        noteColor={Defaults.blackColor}
        busy={busy}
        disabled={!receiptPresent}
        onSubmit={onConfirmPaymentInternal}
        onCancel={() => setShowReceiptRequired(false)}
        customContent={
          <div className={styles.receiptModal}>
            <div className={styles.receiptTip}>
              <HtmlText fontSize={'16px'} k="purchase.deal.receipt-tip" />
            </div>
            {purchase.deal?.expire_in && (
              <div className={styles.timer}>
                {`${t('purchase.deal.upload-until')}`}
                <Timer time={purchase.deal?.expire_in * 2} isNew />
              </div>
            )}
            <div className={styles.receiptIcon}>🧾</div>
            <AttachmentBox
              className={styles.attachReceipt}
              iconElement={t('purchase.deal.add-file')}
              onChange={onSendReceipt}
            />
            {receiptPresent && <>{receiptPresent}</>}
          </div>
        }
      />
      <span>
        {initialized && (
          <HtmlText
            className={styles.headerText}
            k={`flash-pay.payment.perform-payment`}
            args={[
              (purchase.deal ?? purchase.autodeal).amount_currency ?? '',
              purchase.currency?.toUpperCase() ?? '',
            ]}
          />
        )}
      </span>
      <div className={styles.paymentBlock}>
        <PayCard
          broker={broker}
          selfRequisites={false}
          expireIn={purchase.deal?.expire_in ?? purchase.autodeal?.expire_in}
          separated
          requisitesText={t('purchase.deal.payment-4')}
          requisites={
            purchase.deal?.requisite ??
            purchase.requisites ??
            t('flash-pay.requisites-waiting')
          }
        />
        <div className={styles.hints}>
          {!purchase.autodeal && (
            <TabsComponent
              onChange={notifications.setActiveTab}
              className={styles.tabs}
              noGrayscale
              centered
              defaultTab={'none'}
              content={{
                ...tabs,
              }}
              tabs={[
                {
                  id: 'chat',
                  icon: <ToMessageIcon />,
                  caption: 'purchase.deal.tabs.chat',
                  component: (
                    <UnreadMessagesBox count={notifications.personalChat.unread.length} />
                  ),
                },
              ]}
            />
          )}
          <div className={styles.paymentId}>
            {`${t('flash-pay.payment-id')}`}
            <span className={styles.id}>
              {purchase.deal?.id ?? purchase.autodeal.identificator}
            </span>
          </div>
          <div className={styles.numeratedHints}>
            {['hint-2', 'hint-3', 'hint-4'].map((text, index) => (
              <div className={styles.numeratedHint}>
                <div className={styles.hintNumber}>{index + 1}</div>
                <div className={styles.hintText}>{`${t(`purchase.${text}`)}`}</div>
              </div>
            ))}
          </div>
          <ul className={styles.additionalHints}>
            {initialized &&
              ['warning-1', 'warning-2'].map((text) => (
                <li className={styles.warningText}>{`${formatString(
                  t(`flash-pay.${text}`),
                  (purchase.deal ?? purchase.autodeal).amount_currency ?? '',
                  purchase.currency?.toUpperCase() ?? '',
                )}`}</li>
              ))}
          </ul>
          <Row className={styles.actions} gap={'16px'}>
            <DealCancelAction
              className={styles.action}
              caption={'purchase.deal.action.cancel'}
              onClick={confirmCancel}
              width={'fit-content'}
            />
            <DealAction
              className={styles.action}
              caption={'purchase.deal.action.confirm-payment'}
              onClick={confirmPayment}
              width={'20vh'}
            />
          </Row>
        </div>
      </div>
    </div>
  );
};
