import { BankType } from '../components/common/types';
import { LanguageEnum } from '../config/i18n';
export interface UserProfile {
  nickname: string;
  deals: {
    amount_currency: number;
    deals: number;
  };
  rating: number;
  currency: string;
  likes: number;
  dislikes: number;
  registered: number;
  verified: boolean;
  email?: string;
  online?: boolean;
  is_mfa_enabled?: boolean;
}

export type BidType = 'buy' | 'sell';

export interface Bid {
  id?: string;
  type?: BidType;
  symbol?: string;
  broker: BankType;
  rate?: number;
  coefficient?: number;
  limit_from?: number;
  limit_to?: number;
  amount_limit?: number;
  currency?: string;
  details?: string;
  is_active?: boolean;
}

export interface DealBid extends Bid {
  user: string;
}

export const DEAL_PURCHASE = 2;
export const DEAL_SALE = 3;
export type DealType = number;

export type DealState = 'new' | 'proposed' | 'confirmed' | 'paid' | 'closed' | 'deleted';

export interface Deal {
  id: string;
  address?: string;
  symbol: string;
  lot: DealBid;
  rate: number;
  state: DealState;
  amount: number;
  amount_currency: number;
  seller: UserProfile;
  seller_commission: number;
  autodeal?: string;
  buyer: UserProfile;
  buyer_commission: number;
  mask?: string;
  referral_commission_buyer: number;
  referral_commission_seller: number;
  requisite: string;
  created_at: string; // need to be parsed to Date
  end_time: string; // need to be parsed to Date
  expire_in: number | null;
  voted: boolean;
  cancel_reason: string | null;
  is_currency_amount?: boolean;
  dispute: {
    initiator: string;
    opponent: string | null;
    created_at: string; // need to be parsed to Date
  } | null;
}

export interface Autodeal {
  identificator: string;
  amount_currency: number;
  expire_in?: number;
  state: DealState;
  symbol: string;
  broker: Broker;
  currency: string;
  payment: string;
}

export interface DealResponse {
  success: string;
}

export interface Message {
  created_at: string; // need to be parsed to Date
  receiver: string;
  sender: string;
  message?: string;
  media?: string;
}

export interface SendMessage {
  receiver: string;
  message?: string;
  media_id?: number;
  symbol?: string;
}

export interface Notification {
  id: number;
  type:
    | 'deal'
    | 'cancel_deal'
    | 'income_referral'
    | 'message'
    | 'timeout'
    | 'transaction'
    | 'dispute'
    | 'closed_dispute'
    | 'promocode';
  created_ago: number;
  is_read: boolean;
  details: any;
}

export interface Commission {
  symbol: string;
  commission: number;
  commission_buyer: number;
  min_amount?: number;
}

export interface CommissionShort {
  commission: number;
  symbol: 'eth' | 'usdt' | 'btc';
}

export interface Purchase {
  email?: string | null;
  payment_id: string;
  cancel_reason?: string;
  autodeal?: Autodeal;
  symbol: string;
  amount: number;
  broker_id: string;
  is_currency_amount: boolean;
  is_receipt_sent: boolean;
  buyer_id?: number;
  label?: string;
  merchant_id: number;
  merchant: Merchant;
  deal?: Deal;
  page_opened_at?: string;
  mask?: string;
  status: number;
  created_at: string;
  processed_at: string;
  requisites?: string;
  address: string;
  token?: string;
  tx_hash?: string;
  back_url?: string;
  currency?: string;
  lang?: string;
}

export interface PurchaseV2 {
  autodeal: string;
  amount: number;
  broker_id: string;
  confirmed_at: string | null;
  created_at: string;
  currency: string;
  deal: Deal | null;
  lang?: LanguageEnum;
  fiat_sent: boolean;
  id: string;
  mask: string | null;
  is_currency_amount: boolean;
  label: string | null;
  merchant_id: number;
  processed_at: string | null;
  received_crypto: number;
  requisites: string | null;
  status: number;
  symbol: string;
  valid_minutes: number;
}

export interface Sale {
  id: string;
  is_approved: boolean;
  cancel_reason?: string;
  email: string;
  status: number;
  symbol: string;
  amount: number;
  broker_id: string;
  label?: string;
  deal: Deal;
  requisite?: string;
  merchant_id: number;
  is_currency_amount: boolean;
  merchant: Merchant;
  web_link: string;
  token?: string;
  created_at: string;
  processed_at: string;
  back_url?: string;
  currency?: string;
  lang?: string;
}

export interface SaleV2 {
  autodeal: string;
  amount: number;
  currency: string;
  broker_id: string;
  cancel_reason: string | null;
  client_order_id: string | null;
  created_at: string;
  deal: Deal | null;
  lang?: LanguageEnum;
  is_currency_amount: boolean;
  id: string;
  merchant_id: number;
  processed_at: string | null;
  requisites: string;
  sent_crypto: number;
  status: number;
  symbol: string;
  valid_minutes: number;
}

export interface Merchant {
  name: string;
  website?: string;
  image_url?: string;
  commission?: number;
  required_mask?: boolean;
  allow_waiting_back_url: boolean;
  is_receipt_required: boolean;
}

export interface Broker {
  id: string;
  name: string;
  is_card?: boolean;
  logo?: string;
  is_available?: boolean;
}

export interface FlashPayBroker {
  broker_id: string;
  logo?: string;
  name: string;
}

export interface CPayment {
  address?: string;
  amount?: number;
  amount_currency: number;
  amount_received: number;
  amount_left: number;
  created_at: string;
  currency: string;
  ended_at?: string;
  id: string;
  rate?: number;
  status: number;
  symbol?: string;
}
